
import myHome from '../pages/myHome'

export default [
  {
    path: '/',
    name: 'myOrder',
    component: myHome,
    redirect: '/myOrder', // 重定向
    children:[
      {
        path: 'myOrder',
        name: 'myOrder',
        component: () => import('../pages/order/myOrder.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'myOrderProduct',
    component: myHome,
    redirect: '/myOrderProduct', // 重定向
    children:[
      {
        path: 'myOrderProduct',
        name: 'myOrderProduct',
        component: () => import('../pages/order/myOrderProduct.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'myShow',
    component: myHome,
    redirect: '/myShow', // 重定向
    children:[
      {
        path: 'myShow',
        name: 'myShow',
        component: () => import('../pages/my/myShow.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'myliveRecond',
    component: myHome,
    redirect: '/myliveRecond', // 重定向
    // component: Login
    children:[
      {
        path: 'myliveRecond',
        name: 'myliveRecond',
        component: () => import('../pages/my/myliveRecond.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeLive',
    component: myHome,
    redirect: '/homeLive', // 重定向
    children:[
      {
        path: 'homeLive',
        name: 'homeLive',
        component: () => import('../pages/my/homeLive.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeLiveRecond',
    component: myHome,
    redirect: '/homeLiveRecond', // 重定向
    children:[
      {
        path: 'homeLiveRecond',
        name: 'homeLiveRecond',
        component: () => import('../pages/my/homeLiveRecond.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeLivePost',
    component: myHome,
    redirect: '/homeLivePost', // 重定向
    children:[
      {
        path: 'homeLivePost',
        name: 'homeLivePost',
        component: () => import('../pages/my/homeLivePost.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeShow',
    component: myHome,
    redirect: '/homeShow', // 重定向
    children:[
      {
        path: 'homeShow',
        name: 'homeShow',
        component: () => import('../pages/my/homeShow.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeShowPost',
    component: myHome,
    redirect: '/homeShowPost', // 重定向
    children:[
      {
        path: 'homeShowPost',
        name: 'homeShowPost',
        component: () => import('../pages/my/homeShowPost.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeCulture',
    component: myHome,
    redirect: '/homeCulture', // 重定向
    children:[
      {
        path: 'homeCulture',
        name: 'homeCulture',
        component: () => import('../pages/my/homeCulture.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeCulturePost',
    component: myHome,
    redirect: '/homeCulturePost', // 重定向
    children:[
      {
        path: 'homeCulturePost',
        name: 'homeCulturePost',
        component: () => import('../pages/my/homeCulturePost.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homeProduct',
    component: myHome,
    redirect: '/homeProduct', // 重定向
    children:[
      {
        path: 'homeProduct',
        name: 'homeProduct',
        component: () => import('../pages/my/homeProduct.vue')
      }
    ]
  },

  {
    path: '/',
    name: 'homeProduct',
    component: myHome,
    redirect: '/homeProduct', // 重定向
    children:[
      {
        path: 'homeProduct',
        name: 'homeProduct',
        component: () => import('../pages/my/homeProduct.vue')
      }
    ]
  },

  {
    path: '/',
    name: 'homeProductPost',
    component: myHome,
    redirect: '/homeProductPost', // 重定向
    children:[
      {
        path: 'homeProductPost',
        name: 'homeProductPost',
        component: () => import('../pages/my/homeProductPost.vue')
      }
    ]
  },

  {
    path: '/',
    name: 'homearticle',
    component: myHome,
    redirect: '/homearticle', // 重定向
    children:[
      {
        path: 'homearticle',
        name: 'homearticle',
        component: () => import('../pages/my/homearticle.vue')
      }
    ]
  },

  {
    path: '/',
    name: 'homearticle',
    component: myHome,
    redirect: '/homearticle', // 重定向
    children:[
      {
        path: 'homearticle',
        name: 'homearticle',
        component: () => import('../pages/my/homearticle.vue')
      }
    ]
  },

    {
    path: '/',
    name: 'homearticlePost',
    component: myHome,
    redirect: '/homearticlePost', // 重定向
    children:[
      {
        path: 'homearticlePost',
        name: 'homearticlePost',
        component: () => import('../pages/my/homearticlePost.vue')
      }
    ]
  },


  {
    path: '/',
    name: 'homeProject',
    component: myHome,
    redirect: '/homeProject', // 重定向
    children:[
      {
        path: 'homeProject',
        name: 'homeProject',
        component: () => import('../pages/my/homeProject.vue')
      }
    ]
  },

  {
    path: '/',
    name: 'homeProject',
    component: myHome,
    redirect: '/homeProject', // 重定向
    children:[
      {
        path: 'homeProject',
        name: 'homeProject',
        component: () => import('../pages/my/homeProject.vue')
      }
    ]
  },


  {
    path: '/',
    name: 'homeProjectPost',
    component: myHome,
    redirect: '/homeProjectPost', // 重定向
    children:[
      {
        path: 'homeProjectPost',
        name: 'homeProjectPost',
        component: () => import('../pages/my/homeProjectPost.vue')
      }
    ]
  },


]