<template>
  <div class="index">
    <!-- 导航列表 -->
    <div class="">
      <!-- 数据总览 -->
      <dataScreening :detail="detail"></dataScreening>
      <!-- 文创产品 -->
      <Product :detail="detail"></Product>
      <!-- 看直播 -->
      <live :detail="detail"></live>
      <!-- 线上展鉴 -->
      <show :detail="detail"></show>
      <!-- 乡村振兴 -->
      <village :detail="detail"></village>
      <!-- 文化配送 -->
      <Delivery :detail="detail"></Delivery>
      <!-- 友情链接 -->
      <service-bar :detail="detail"></service-bar>
      <nav-footer :detail="detail" />

      </div>
  </div>
</template>

<script>
import ServiceBar from "@/components/ServiceBar.vue"
import village from "@/components/village.vue"
import dataScreening from "@/components/dataScreening.vue"
import live from "@/components/live.vue"
import Delivery from '@/components/Delivery.vue'
import Product from '@/components/Product.vue'
import show from '@/components/show.vue'
import NavFooter from '@/components/NavFooter.vue'; //友情链接

export default {
  name: "index",
  components: { dataScreening, village, ServiceBar, live ,Delivery,Product,show,NavFooter},
  data() {
    return {

      detail:{},
    }
  },
  created(){
    this.init()
  },
  mounted() {

  },
  methods: {
    // 初始化商品列表
    init() {
        this.$api.mall.appHome().then(res=>{
          // console.log(res)
             this.detail=res.data;
             //赋值给子组件名为数据
             this.detail.arrayList=[
              {
              number:this.detail.mathNum,
              text:'平台资源数字（条）',
              },
              {
              number:this.detail.viewNum,
              text:'平台访问数（人）',
              },
              {
              number:this.detail.appointmentNum,
              text:'平台活动预约数（人次）',
              },
              {
              number:this.detail.platNum,
              text:'平台注册用户数（人)',
              },
            ]
        })
    },
    // 加入购物车
    addCart() {
      this.showModal = true
      
    },
    // 去购物车
    goCart() {
      this.$router.push("/cart")
    },
  },
}
</script>

<style lang="scss" scoped>
.index {
  .swiper-box {
    .nav-menu {
      position: absolute;
      width: 264px;
      height: 451px;
      z-index: 9;
      padding: 26px 0;
      background: #55585a7a;
      box-sizing: border-box;
      .menu-wrap {
        .menu-item {
          height: 50px;
          line-height: 50px;
          a {
            position: relative;
            display: block;
            font-size: 16px;
            color: #fff;
            padding-left: 30px;
            &::after {
              position: absolute;
              right: 30px;
              top: 17.5px;
              content: "";
              @include bgImg(10px, 15px, "/imgs/icon-arrow.png");
            }
          }
          &:hover {
            background-color: $colorA;
            .children {
              display: block;
            }
          }
          .children {
            display: none;
            width: 962px;
            height: 451px;
            background-color: $colorG;
            position: absolute;
            top: 0;
            left: 264px;
            border: 1px solid $colorH;
            ul {
              display: flex;
              justify-content: space-between;
              height: 75px;
              li {
                height: 75px;
                line-height: 75px;
                flex: 1;
                padding-left: 23px;
              }
              a {
                color: $colorB;
                font-size: 14px;
              }
              img {
                width: 42px;
                height: 35px;
                vertical-align: middle;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
    .swiper-container {
      height: 451px;
      .swiper-button-prev {
        left: 274px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ads-box {
    @include flex();
    margin-top: 14px;
    margin-bottom: 31px;
    a {
      width: 296px;
      height: 167px;
    }
  }
  .banner {
    margin-bottom: 50px;
  }
  .product-box {
    background: $colorJ;
    padding: 30px 0 50px;
    h2 {
      font-size: 22px;
      height: 21px;
      color: $colorB;
      margin-bottom: 20px;
    }
    .wrapper {
      display: flex;
      .banner-left {
        margin-right: 16px;
        img {
          width: 224px;
          height: 619px;
        }
      }
      .list-box {
        .list {
          display: flex;
          @include flex();
          width: 986px;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
          .item {
            width: 236px;
            height: 302px;
            background: $colorG;
            text-align: center;
            span {
              display: inline-block;
              width: 67px;
              height: 24px;
              line-height: 24px;
              color: $colorG;
              border-radius: 5px;
              margin-top: 5px;
              &.new-pro {
                background: #7ecf68;
              }
              &.kill-pro {
                background: #e82626;
              }
            }
            .item-img {
              img {
                width: 100%;
                height: 195px;
              }
            }
            .item-info {
              h3 {
                font-size: 14px;
                color: $colorB;
                line-height: 14px;
                font-weight: bold;
              }
              p {
                color: $colorD;
                line-height: 13px;
                margin: 6px auto 13px;
              }
              .price {
                color: #f20a0a;
                font-size: 14px;
                font-weight: bold;
                cursor: pointer;
                &::after {
                  content: "";
                  @include bgImg(22px, 22px, "/imgs/icon-cart-hover.png");
                  margin-left: 5px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
