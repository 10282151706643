<template>
  <div>
    <nav-header />
    <HeadNav />
    <router-view />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import HeadNav from '@/components/HeadNav.vue';
export default {
  name: 'nav-home',
  components: { NavHeader,HeadNav,  }
};
</script>

<style lang='scss' scoped>
</style>