<template>
<!-- 底部友情链接条-->
  <div class="service">
    <div class="container">
    </div>
  </div>
</template>

<script>
export default {
  name:'service-bar'
}
</script>

<style lang='scss' scoped>

// .service {
//   padding:  0;
//   color: $colorC;
//   font-size: 16px;
//   li {
//     display: inline-block;
//     position: relative;
//     width: 24.9%;
//     text-align: center;
//     &::after {
//       position: absolute;
//       content: '';
//       height: 20px;
//       border-left: 2px solid $colorH;
//       right: 0;
//       top: 0;
//     }
//     &:last-child:after {
//       display: none;
//     }
//     span {
//       display: inline-block;
//       width: 20px;
//       height: 20px;
//       vertical-align: middle;
//       margin-right: 8px;
//     }
//     .icon-setting {
//       @include bgImg(20px, 20px,'/imgs/icon-setting.png');
//     }
//     .icon-7day {
//       @include bgImg(20px, 20px,'/imgs/icon-7day.png');
//     }
//     .icon-15day {
//       @include bgImg(20px, 20px,'/imgs/icon-15day.png');
//     }
//     .icon-post {
//       @include bgImg(20px, 20px,'/imgs/icon-post.png');
//     }
//   }

// }
</style>
