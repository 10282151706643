<template>
         <div class="my-left">
           <el-menu
             :default-active="$route.path"
             mode="vertical"
             class="el-menu-vertical-demo"
             background-color="#D9DADC"
             active-text-color="#666666"
             text-color="#666666"
             router
             @open="handleOpen"
             @close="handleClose">
             <el-submenu :index="item.path+''" v-for="(item,index) in myList" :key="item.path" :route="item.path">
                 <template #title>
                         <img src="../../assets/image/icon_shoucan.png" class="icon-start" alt="">
                         <span>{{item.name}}</span>
                 </template>
                <el-menu-item :index="subitem.path+''" v-for="(subitem,two) in item.children" :key="subitem.path" :route="subitem.path">{{subitem.name}}</el-menu-item>
              </el-submenu>
          </el-menu>
         </div>
 </template>
 
 <script>
   export default {
     data(){
       return {
           myList:[
             {
               index:'1',
               name:'我的收藏',
               path:'/mycollect',
               children:[
                 {
                   index:'1-1',
                   name:'直播',
                   path:'/mylive',
                 },
                 {
                   index:'1-0',
                   name:'直播回放',
                   path:'/myliveRecond',
                 },
                 {
                   index:'1-2',
                   name:'文化配送',
                   path:'/myDelivery',
                 },
                 {
                   index:'1-3',
                   name:'文创产品',
                   path:'/myProduct',
                 },
                 {
                   index:'1-4',
                   name:'乡村振兴',
                   path:'/myvillage',
                 },
                 {
                   index:'1-5',
                   name:'线上展览',
                   path:'/myShow',

                 },
               ]
             },
             {
               index:'2',
               name:'我的内容',
               path:'/myContent',
               children:[
                 {
                   index:'2-1',
                   name:'我的直播列表',
                   path:'/homeLive',
                 },
                 {
                   index:'2-2',
                   name:'我的直播回放列表',
                   path:'/homeLiveRecond',
                 },
                //  {
                //    index:'2-3',
                //    name:'直播申请',
                //    path:'/homeLivePost',
                //  },
                 {
                   index:'2-4',
                   name:'我的展览',
                   path:'/homeShow',
                 },
                //  {
                //    index:'2-5',
                //    name:'申请展览',
                //    path:'/homeShowPost',
                //  },
                 {
                   index:'2-6',
                   name:'我的企业名录',
                   path:'/homeCulture',
                 },
                //  {
                //    index:'2-7',
                //    name:'企业名录申请',
                //    path:'/homeCulturePost',
                //  },
                 {
                   index:'2-8',
                   name:'我的文创产品',
                   path:'/homeProduct',
                 },
                //  {
                //    index:'2-9',
                //    name:'申请文创产品',
                //    path:'/homeProductPost',
                //  },
                 {
                   index:'2-10',
                   name:'我的栏目文章',
                   path:'/homearticle',
                 },
                //  {
                //    index:'2-11',
                //    name:'申请栏目文章',
                //    path:'/homearticlePost',
                //  },
                 {
                   index:'2-12',
                   name:'我的文化配送',
                   path:'/homeProject',
                 },
                //  {
                //    index:'2-13',
                //    name:'申请文化配送',
                //    path:'/homeProjectPost',
                //  },
               ]
             },
             {
               index:'3',
               name:'我的订单',
               path:'/myOrder',
               children:[
                 {
                   index:'3-1',
                   name:'我的文化配送',
                   path:'/myOrder',
                 },
                 {
                   index:'2-2',
                   name:'我的文创产品',
                   path:'/myOrderProduct',
                 },
             
               ]
             }
           ]
       }
     },
     methods: {
       handleOpen(key, keyPath) {
         console.log(key, keyPath);
       },
       handleClose(key, keyPath) {
         console.log(key, keyPath);
       }
     }
   }
 </script>
 
 <style lang='scss' scoped>
 .wrapper{
   background: #F2F2F2;
 }
 .container{
   padding-top:20px;
   padding-bottom:120px;
 
 }
 .my-left{
     width:314px;
     min-height:800px;
     text-align: left;
     background:#D9DADC;
     overflow: hidden;
     float:left;
 }
 .el-menu-vertical-demo{
     background:#D9DADC;
 
 }
 .icon-start{
     width:24px;
     height:20px;
     margin-right:10px;
 }

 </style>

<style scoped>
.el-menu-item{
    font-size:14px !important;
    padding-left:55px !important;
}
.el-menu-item.is-active{
    color: #666666 !important;
    background: #BDBDBD !important;

}
.el-submenu__title *{
    font-size:16px;
}
</style>