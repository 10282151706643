<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-close="false"
      top="20vh"
      custom-class="customClass"
    >
      <ul class="live-li">
        <li
          v-for="(val, index) in loginTabs"
          @click="changes(index)"
          :key="index"
          :class="{ active: index == num }"
        >
          {{ val }}
        </li>
      </ul>
      <el-input
        class="phone-input"
        v-model="phone"
        placeholder="请输入手机号"
      ></el-input>
      <el-input
        class="phone-input"
        type="password"
        v-model="password"
        v-if="num == 0"
        placeholder="请输入密码"
      ></el-input>
      <el-input
        class="phone-input"
        style="width: 245px"
        type="text"
        v-model="code"
        v-else
        placeholder="请输入验证码"
      ></el-input>
      <span v-if="num == 1" class="btn-getcode">
        <el-button v-show="show" @click="getCodePhone">获取验证码</el-button>
        <el-button v-show="!show">{{ count }}s</el-button>
      </span>

      <el-input
        class="phone-input"
        v-model="imageCode"
        auto-complete="off"
        placeholder="验证码"
        style="width: 63%"
      >
      </el-input>
      <div class="login-code">
        <img :src="codeUrl" @click="getCode" class="login-code-img" />
      </div>
      <div>
        <div class="log-fore">
          <span class="">
            <el-checkbox v-model="checked">我已接受<a @click="handleServer">《登录协议》</a></el-checkbox>
          </span>
          <span class="pull-right" @click="handleForget">忘记密码？</span>
        </div>
      </div>

      <div class="container-button">
        <button class="login_button" @click="getLogin">登录</button>
        <p class="login_accout" @click="getRegister" v-if="getRegisterStatus">
          我没有账号，点我立即注册
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["dialogVisible"],
  name: "nav-header",
  data() {
    return {
      timer: null,
      codeUrl: "",
      count: 60, //倒计时秒
      show: true,
      num: 0,
      loginTabs: ["密码登录", "手机登录"],
      checked: true, // 默认选中
      codeUrl: "",
      code: "",
      phone: "",
      password: "",
      imageCode: "",
      uuid: "",
      showRegister: false,
    };
  },
  mounted() {
    if (!this.$cookie.get("userId")) {
      this.$nextTick(() => {
        this.getCode();
      });
    }
  },
  computed: {
    // 获取store中state值
    // 方式一：
    // username() {
    //   return this.$store.state.username;
    // },
    // cartCount() {
    //   return this.$store.state.cartCount;
    // }
    // 方式二：
    ...mapState(["username", "cartCount"]),
    getRegisterStatus() {
      let path = location.hash; 
      if (path !== "#/register") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions(["saveUserName"]),
    //发送难码接口
    getCode() {
      this.$api.mall.getCodeImg().then((res) => {
        this.captchaOnOff =
          res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          console.log("2");
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.uuid = res.uuid;
        }
      });
    },
    handleServer(){
      this.$router.push("/server");
    },
    //忘记密码功能
    handleForget(){
      this.$router.push("/forget");
    },
    changes(item) {
      this.num = item;
    },
    handleClose() {
      this.$emit("close");
    },
    //去注册页面
    getRegister() {
      this.$router.push("/register");
    },
    //账号对话框tabs选项效果
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //注册按钮
    handleRegister() {
      this.getRegister();
    },
    //获取手机验证码
    getCodePhone() {
      if (/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.phone)) {
        this.$api.mall.getCodePhone("app_login", this.phone).then((res) => {
          console.log(res);
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          this.$message({
            message: "手机验证码发送成功",
            type: "success",
          });
        });
      } else {
        this.$message({
          message: "请输入正确的手机号码",
          type: "error",
        });
      }
    },
    //登录请求接口
    getLogin() {
      if(!this.checked){
        this.$message({
          message: "请勾选登录协议并阅读",
          type: "error",
        });
      };
      let { phone, password, imageCode, uuid, code } = this;
      if(phone==''){
        this.$message({
          message: "手机号码不能为空",
          type: "error",
        });
        return false;
      }

      if(imageCode==''){
        this.$message({
          message: "验证码不能为空",
          type: "error",
        });
        return false;
      }
      if (this.num == 1) {
     
        this.$api.mall
          .codeLogin({
            phone,
            code,
            imageCode,
            uuid,
          })
          .then((res) => {
            console.log(res.token);
            // vue-cookie插件：用户id保存7天
            this.$cookie.set("userId", res.token, { expires: "Session" });
            this.$router.go(0);
            this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            // vuex：保存用户名
            // this.$store.dispatch('saveUserName', res.username);
            this.saveUserName(res.username);
          });
      } else {
        if(password==''){
          this.$message({
            message: "密码不能为空",
            type: "error",
          });
          return false;
        }
        this.$api.mall
          .login({
            phone,
            password,
            imageCode,
            uuid,
          })
          .then((res) => {
            console.log(res.token);
            // vue-cookie插件：用户id保存7天
            this.$cookie.set("userId", res.token, { expires: "Session" });
            this.$router.go(0);
            // this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
            // vuex：保存用户名
            this.$store.dispatch("saveUserName", res.username);
            this.saveUserName(res.username);
          });
      }
    },
    // 登录
    goLogin() {
      this.$router.push("/login");
    },

    // 获取购物车商品数量
  },
};
</script>

<style lang="scss" scoped>
.pull-right{
  cursor: pointer;
}
i {
  font-style: normal;
}
// @import "./../assets/scss/base.scss";
// @import "./../assets/scss/config.scss";

//tabs选项效果
.live-li {
  display: inline-block;
  li {
    float: left;
    text-align: center;
    height: 37px;
    line-height: 37px;
    color: #999999;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 0 5px;
    margin: 0 30px;
  }
  .active {
    border-bottom: 2px solid #00A58F;
    color: #00A58F;
  }
}
.header {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .logo_img {
    width: 352px;
    height: 60px;
    margin-top: 36px;
    margin-bottom: 28px;
  }
  .input_right {
    border: 1px solid #999;
    width: 460px;
    height: 40px;
    border-radius: 20px;
    padding: 14px 20px 12px 29px;
    position: relative;
    margin-right: 41px;
    background: url("/imgs/icon_sous@2x.png") no-repeat right 20px center / 18px
      18px;
    &::placeholder {
      color: #999;
    }
  }
  .login_input {
    margin-top: 48px;
  }
  .login {
    font-size: 18px;
    display: inline-block;
    cursor: pointer;
  }
  .icon-person {
    display: inline-block;
    background: url("/imgs/icon_renxiang@2x.png") no-repeat;
    background-size: contain;
    vertical-align: -4px;
    width: 18px;
    height: 22px;
    margin-right: 10px;
  }
  .nav-topbar {
    height: 39px;
    line-height: 39px;
    background-color: #333333;
    color: #b0b0b0;
  }
}
.phone-input {
  margin-top: 20px;
}
.container-button {
  text-align: center;
}
.login_button {
  background: #00A58F;
  border-radius: 36px;
  border: none;
  color: #fff;
  font-size: 22px;
  padding: 8px 159px;
  margin: 10px;
  cursor: pointer;
}
.login_accout {
  color: #00A58F;
  cursor: pointer;
  padding-bottom: 20px;
}

//图形验证码
.login-code {
  margin-top: 20px;
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.login-code-img {
  height: 38px;
}
.log-fore {
  margin-top: 10px;
}
.btn-getcode {
  button {
    padding: 15px;
    background: #00A58F;
    color: #fff;
    font-size: 10px;
    margin: 0 10px;
  }
}
</style>

<style>
.customClass .el-dialog__body {
  padding: 0 20px;
}
</style>
