var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.detail.articleInfos)?_c('div',{staticClass:"village"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"grid-content1",on:{"click":function($event){return _vm.handleJump(_vm.detail.articleInfos[0])}}},[_c('div',{staticClass:"leftBottom"},[_c('img',{staticClass:"contentImg",attrs:{"src":_vm.detail.articleInfos[0].image}})]),_c('div',{staticClass:"leftBottom2"},[_c('img',{staticClass:"contentImgdown",attrs:{"src":"https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323180939616.png"}}),_c('h3',[_vm._v(_vm._s(_vm.detail.articleInfos[0].title))]),_c('p',{staticClass:"dewy-content",domProps:{"innerHTML":_vm._s(
                _vm.detail.articleInfos[0].content.slice(0,100).replace(
                  /<\/?(img)[^>]*>/gi,
                  ''
                )
              )}})])])]),_c('el-col',{attrs:{"span":12}},_vm._l((_vm.detail.articleInfos),function(item,index){return _c('div',{staticClass:"dis-flex box-content"},[_c('div',{staticClass:"left-content"},[_c('div',[_c('div',{staticClass:"f-24",staticStyle:{"font-weight":"bold","margin":"5px 0 10px 0"}},[_vm._v(_vm._s(item.createTime.slice(8, 10)))]),_c('div',{staticClass:"f-16"},[_vm._v(_vm._s(item.createTime.slice(0, 7)))])]),_c('div',{staticClass:"line-content"})]),_c('div',{staticClass:"right-content",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleJump(item)}}},[_c('div',{staticClass:"rigthOne"},[_c('h1',[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"dewy-content",domProps:{"innerHTML":_vm._s(item.content.slice(0,50).replace(/<\/?(img)[^>]*>/gi, ''))}}),_c('hr',{staticStyle:{"margin-top":"20px"}})])])])}),0)],1)],1),_c('div',{staticClass:"load-more",on:{"click":_vm.loadMore}},[_vm._v("查看更多>>")])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"header_pic",attrs:{"src":"https://qwyun.ai-library.com/imgs/village@2x.png"}})])
}]

export { render, staticRenderFns }