import Vue from 'vue'
import App from './App.vue'
// 全部引入elementUI
import ElementUI from 'element-ui' // 引入elementUI
import router from './router' // 路由
import api from './api' // 导入api接口
import VueLazyLoad from 'vue-lazyload' // 引入懒加载
import VueCookie from 'vue-cookie' // 引入cookie
import store from './store' // 引入vuex

// import axios from 'axios' // 网络请求：axios是一个库，并不是vue中的第三方插件，使用时不能通过Vue.use()安装插件，需要在原型上进行绑定
// import VueAxios from 'vue-axios' // vue-axios是将axios集成到Vue.js的小包装器，可以像插件一样进行安装
// import env from './config/env' // 环境变量

import {  selectDictLabel, selectDictLabels,handleTree} from "@/util/index";
import '@/assets/scss/reset.scss'; // 引入重置样式表（覆盖浏览器用户代理样式）
import '@/assets/scss/index.css'
import '@/assets/scss/icon.scss'
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 全局方法挂载
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree

//审核挂在全局方法
Vue.prototype.auditStateFormart=function(msg){
    return selectDictLabel(store.state.audit_state,msg)
}
Vue.component('ImageUpload', ImageUpload)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}


Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

Vue.use(ElementUI)
// Vue.prototype.$message = Message;
// Vue.use(Button);

Vue.use(VueLazyLoad, {
  loading: '/imgs/loading-svg/loading-bars.svg'
})
Vue.use(VueCookie)
Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.config.productionTip = false  // 关闭生产模式下给出的提示




new Vue({
  render: h => h(App), // h函数会生成一个VNode节点，render函数得到这个VNode节点之后，返回给Vue.js的mount函数渲染成真实DOM节点，并挂载到根节点上。
  router,
  store,
}).$mount('#app')
