/**
 * 路由封装
 * 路由懒加载方式一：
 *    1. component: resolve => require(['../pages/Index.vue'],resolve)  // 路由懒加载
 * 路由懒加载方式二：
 *    1. 需要添加 syntax-dynamic-import (opens new window)插件，才能使 Babel 可以正确地解析语法,`cnpm install --save-dev @babel/plugin-syntax-dynamic-import`
 *    2. component: () => import('../pages/Cart.vue'), // 路由懒加载
 */
// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'
// 导入页面组件
// import Login from '../pages/Login'
import Home from '../pages/Home'
import myHome from '../pages/myHome'
import Index from '../pages/Index'
import my from '@/router/my'; //业务路由
// 加载路由插件
const changePush = Router.prototype.push;
Router.prototype.push = function push(location){
    return changePush.call(this, location).catch(err => err);
}

Vue.use(Router)



// 导出路由
export default new Router({
  // 路由规则
  routes: [
    // 首页
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/index', // 重定向
      // 子路由
      children: [
        {
          path: 'index',
          name: 'index',
          component: Index,
        },
        {
          path: 'product/:id', // 动态路由
          name: 'product',
          // component: Product,
          // component: resolve => require(['../pages/Product.vue'],resolve), // 路由懒加载
          component: () => import('../pages/Product.vue') // 路由懒加载
        },
        {
          path: 'detail/:id',
          name: 'detail',
          // component: Detail,
          // component: resolve => require(['../pages/Detail.vue'], resolve), // 路由懒加载
          component: () => import('../pages/Detail.vue') // 路由懒加载
        }
      ]
    },
    //直播
    {
      path: '/',
      name: 'live',
      component: Home,
      redirect: '/live', // 重定向
      // component: Login
      children:[
        {
          path: 'live',
          name: 'live',
          component: () => import('../pages/live.vue')
        },
        {
          path: 'liveDetail',
          name: 'liveDetail',
          component: () => import('../pages/live/detail.vue')
        }
      ]
    },
    //文化配送
    {
      path: '/',
      name: 'Delivery',
      component: Home,
      redirect: '/live', // 重定向
      // component: Login
      children:[
        {
          path: 'Delivery',
          name: 'Delivery',
          component: () => import('../pages/Delivery.vue')
        },
        {
          path: 'DeliveryDetail',
          name: 'DeliveryDetail',
          component: () => import('../pages/Delivery/detail.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'searchName',
      component: Home,
      redirect: '/live', // 重定向
      // component: Login
      children:[
        {
          path: 'searchName',
          name: 'searchName',
          component: () => import('../pages/searchName.vue')
        },
      ]
    },
    //文创产品
    {
      path: '/',
      name: 'product',
      component: Home,
      redirect: '/', // 重定向
      // component: Login
      children:[
        {
          path: 'product',
          name: 'product',
          component: () => import('../pages/Product.vue')
        },
        {
          path: 'productlist',
          name: 'productlist',
          component: () => import('../pages/Product/list.vue')
        },
        {
          path: 'ProductDetail',
          name: 'ProductDetail',
          component: () => import('../pages/Product/detail.vue')
        },
        {
          path: 'companyDetail',
          name: 'companyDetail',
          component: () => import('../pages/Product/companyDetail.vue')
        }
      ]
    },

    {
      path: '/',
      name: 'village',
      component: Home,
      redirect: '/village', // 重定向
      // component: Login
      children:[
        {
          path: 'village',
          name: 'village',
          component: () => import('../pages/village.vue')
        },
        {
          path: 'villageDetail',
          name: 'villageDetail',
          component: () => import('../pages/village/detail.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'show',
      component: Home,
      redirect: '/show', // 重定向
      // component: Login
      children:[
        {
          path: 'show',
          name: 'show',
          component: () => import('../pages/show.vue')
        },
        {
          path: 'showDetail',
          name: 'showDetail',
          component: () => import('../pages/show/detail.vue')
        },
        
      ]
    },
      // component: () => import('../pages/live.vue') // 路由懒加载
      //我的模块
    {
      path: '/',
      name: 'mylive',
      component: myHome,
      redirect: '/mylive', // 重定向
      // component: Login
      children:[
        {
          path: 'mylive',
          name: 'mylive',
          component: () => import('../pages/my/mylive.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'myDelivery',
      component: myHome,
      redirect: '/myDelivery', // 重定向
      children:[
        {
          path: 'myDelivery',
          name: 'myDelivery',
          component: () => import('../pages/my/myDelivery.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'myProduct',
      component: myHome,
      redirect: '/myProduct', // 重定向
      children:[
        {
          path: 'myProduct',
          name: 'myProduct',
          component: () => import('../pages/my/myProduct.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'myvillage',
      component: myHome,
      redirect: '/myvillage', // 重定向
      children:[
        {
          path: 'myvillage',
          name: 'myvillage',
          component: () => import('../pages/my/myvillage.vue')
        }
      ]
    },
    ...my,
    
    // 登录
    // {
    //   path: '/login',
    //   name: 'login',
    //   // component: Login
    //   component: () => import('../pages/Login.vue') // 路由懒加载
    // },
    // 购物车
    {
      path: '/register',
      name: 'register',
      // component: Cart
      component: () => import('../pages/register.vue') // 路由懒加载
    },
    {
      path: '/forget',
      name: 'forget',
      // component: Cart
      component: () => import('../pages/forget.vue') // 路由懒加载
    },
    {
      path: '/server',
      name: 'server',
      // component: Cart
      component: () => import('../pages/server.vue') // 路由懒加载
    },
    // 订单
    {
      path: '/order',
      name: 'order',
      component: () => import('../pages/Order.vue'), // 路由懒加载
      children: [
        {
          path: 'list',
          name: 'order-list',
          component: () => import('../pages/OrderList.vue') // 路由懒加载
        },
        {
          path: 'confirm',
          name: 'order-confirm',
          component: () => import('../pages/OrderConfirm.vue') // 路由懒加载
        },
        {
          path: 'pay',
          name: 'order-pay',
          component: () => import('../pages/OrderPay.vue') // 路由懒加载
        },
        {
          path: 'alipay',
          name: 'alipay',
          component: () => import('../pages/Alipay.vue') // 路由懒加载
        }
      ]
    }
  ]
})