<template>
  <div class="live-container" >
    <!-- 看直播 -->
    <div class="effed">
      <img src="/imgs/pic_biaoti@2x.png" alt="" class="effed_pic" />
    </div>
    <!-- 做回看 进行中 直播预告 -->
    <div class="live-inge">
      <ul class="live-li">
				<li v-for="(val,index) in tabs" @click="changes(index)" :key="index" :class="{active:index == num}">
              {{val}}
				</li>
			</ul>
    </div>
    <!-- 大框子回看 -->
    <div v-if="detail.videoRecords">
    <div v-if="num==0">
      <div class="live-reify"  >
      <div class="reify-dfr">
        <video preload="none" class="reify_pic" controls="controls"  :poster="detail.videoPres[0].image"  loop  :src="detail.videoRecords[0].videoUrl"></video>
      </div>
      <div>
        <img src="/imgs/icon_bofang@2x.png" alt="" class="dfy_pic" />
      </div>
      <div class="live-zug">
        <h2>{{detail.videoRecords[0].name}}</h2>
        <p>
          {{detail.videoRecords[0].content}}
        </p>
        <!-- 日期 收藏 转发 -->
      </div>
      <div class="live-toffy">
        <p>{{detail.videoRecords[0].createTime}}</p>
      </div>
      <div class="toffy-recv">
        <span><img src="/imgs/icon_shoucang@2x.png" alt="" class="toffy_pic" />收藏</span>
        <span><img src="/imgs/icon_zhuanfa@2x.png" alt="" class="recv_pic" />转发</span>
      </div>
    </div>


    <!-- 两个圆角 -->
      <div class="live-recv">
        <div class="recv-on">
          <div class="on-te"><img src="/imgs/icon_jiantou1@2x.png" alt="" class="te_pic" /></div>
          <div class="on-ne"><img src="/imgs/icon_jiantou2@2x.png" alt="" class="te_pic" /></div>
          <!-- 两个大框子 -->
          <div class="wing-wo">
            <video preload="none" class="wo_pic" controls="controls"   :poster="detail.videoPres[0].image" loop  :src="detail.videoRecords[0].videoUrl"></video>
          </div>
          <div class="wing-we">
            <video preload="none" class="wo_pic" controls="controls"    :poster="detail.videoPres[0].image" loop  :src="detail.videoRecords[0].videoUrl"></video>
          </div>
          <!-- 查看更多 -->
        </div>
      </div>
    </div>
  </div>
  <div v-if="detail.videoIngs">
 <!-- 大框子进行中 -->
    <div v-if="num==1">
      <div class="live-reify"  >
      <div class="tcplayer">
        <TcPlayer ref="TcPlayer" :playVideo="{'m3u8':detail.videoIngs[0].videoUrl}" :widthHeigt="[100, 100]"></TcPlayer>
      </div>
    </div>

      <div class="live-recv">
        <div class="recv-on">

        </div>
      </div>
    </div>
</div>


 <!-- 大框子预告 -->
 <div v-if="detail.videoPres">
 <div v-if="num==2">
      <div class="live-reify"  >
      <div class="reify-dfr">
        <video preload="none" class="reify_pic" controls="controls"  loop  :src="detail.videoPres[0].videoUrl"></video>
      </div>
      <div>
        <img src="/imgs/icon_bofang@2x.png" alt="" class="dfy_pic" />
      </div>
      <div class="live-zug">
        <h2>{{detail.videoPres[0].name}}</h2>
        <p>
          {{detail.videoPres[0].content}}
        </p>
        <!-- 日期 收藏 转发 -->
      </div>
      <div class="live-toffy">
        <p>{{detail.videoPres[0].createTime}}</p>
      </div>
      <div class="toffy-recv">
        <span><img src="/imgs/icon_shoucang@2x.png" alt="" class="toffy_pic" />收藏</span>
        <span><img src="/imgs/icon_zhuanfa@2x.png" alt="" class="recv_pic" />转发</span>
      </div>
    </div>


    <!-- 两个圆角 -->
      <div class="live-recv">
        <div class="recv-on">
          <div class="on-te"><img src="/imgs/icon_jiantou1@2x.png" alt="" class="te_pic" /></div>
          <div class="on-ne"><img src="/imgs/icon_jiantou2@2x.png" alt="" class="te_pic" /></div>
          <!-- 两个大框子 -->
          <div class="wing-wo">
            <video preload="none" class="wo_pic" controls="controls"  loop  :poster="detail.videoPres[0].image" :src="detail.videoPres[0].videoUrl"></video>
          </div>
          <div class="wing-we">
            <video preload="none" class="wo_pic" controls="controls"  loop  :poster="detail.videoPres[0].image" :src="detail.videoPres[0].videoUrl"></video>
          </div>
          <!-- 查看更多 -->
        </div>
      </div>
    </div>
    <div class="load-more" @click="loadMore">查看更多>></div>
</div>
  
  </div>
</template>
<script>
import TcPlayer from "./video/tcPlayer.vue"
export default {
  components: { TcPlayer },
  props:['detail'],
  data(){
    return{
      //
       playVideo: {
          m3u8:'',
          m3u8_sd:'',
          m3u8_hd:'',
      },
      num:0,
      tabs:["回看", "进行中", "直播预告"], //导航栏标题,
      DataList:{
          itemNumber:[],
      },
    }
  },
  methods:{
      /**
     * 查看更多
     */
     loadMore() {
      this.$router.push({
        path: "/live",
        query: {},
      });
    },
    changes(item){
      console.log(item,this.detail)
        if(this.detail.videoIngs.length==0){{
          this.msgError('暂无直播数据')
        }}
        this.detail;
        this.num=item;
    }
  },
  created(){

  },
  mounted() {
  },
}

</script>
<style lang="scss" scoped>

.load-more {
  color: #00A58F;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.live-container {
  overflow: hidden;
  background: url("/imgs/pic_beijing@2x.png") no-repeat;
  background-size: 100% 100%;
  height: 660px;
}
.live-inge {
    text-align: center;
}
.tcplayer{
  width: 500px;
    height: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
}
.more{
  margin:0 auto;text-align:center;cursor:pointer;
  color:#00A58F;
}
//tabs选项效果
.live-li{
  display: inline-block;
    li{
    float: left;
    border-radius: 22px;
    border: 1px solid #00A58F;
    text-align: center;
    width: 106px;
    height: 37px;
    line-height: 37px;
    color:#00A58F;
    font-size: 16px;
    cursor: pointer;
    margin:20px;
    }
    .active{
      background: #00A58F;
      color:#fff;
      border-radius: 22px;
    }
}
// 看直播
.effed {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0 10px 0;
  .effed_pic {
    width: 244px;
    height: 82px;
  }
}

// 回看
.live-effed {
  border-radius: 22px;
  border: 1px solid #00A58F;
  background: #00A58F;
  text-align: center;
  margin-top: -22px;
  margin-left: 33.5%;
  width: 106px;
  height: 37px;
}
.live-effed p {
  color: #ffffff;
  font-size: 16px;
  margin-top: 7%;
}

// 进行中 直播预告
.live-tinges {
  border-radius: 22px;
  border: 1px solid #00A58F;
  text-align: center;
  margin-top: -22px;
  margin-left: 34px;
  width: 106px;
  height: 37px;
}
.live-tinges p {
  color: #00A58F;
  font-size: 16px;
  margin-top: 7%;
}

// 大框子
.live-reify {
  display: inline-block;
  display: flex;
  width: 1200px;
  margin: 0 auto;
}

.reify-dfr {
  text-align: center;
  padding: 30px 0 0 195px;
  .reify_pic {
    width: 472px;
    height: 330px;
  }
}

// 大框子的中国石牌坊之乡
.reify-dfr p {
  font-size: 42px;
  color: #fffefe;
  margin-top: -200px;
  letter-spacing: 12px;
}
.dfy_pic {
  width: 51px;
  height: 52px;
  margin: 165px -265px;
}

// 标题
.live-zug h2 {
  clear: #333333;
  margin: 30px 10px;
  font-size: 19px;
}
.live-zug p {
  clear: #333333;
  margin: -20px 20px;
  font-size: 12px;
  line-height: 21px;
  width: 320px;
  height: 76px;
}

// 日期
.live-toffy p {
  clear: #333333;
  margin: 170px -340px;
  font-size: 17px;
  line-height: 20px;
  width: 320px;
  height: 76px;
}

// 收藏 转发
.toffy_pic {
  width: 15px;
  height: 13px;
  vertical-align: -1px;
}
.recv_pic {
  width: 13px;
  height: 13px;
  vertical-align: -1px;
}

.toffy-recv {
  margin-left: -150px;
  margin-top: 170px;
  padding: 4px 2px;
  letter-spacing: 1px;
}
.toffy-recv span {
  margin-left: 20px;
}

// 两个圆角
.live-recv {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
.recv-on {
  margin: -192px 920px;
}

// 左圆角
.on-te {
  border-radius: 13px 0px 0px 13px;
  border: 1px solid #00A58F;
  width: 40px;
  height: 27px;
}

// 右圆角
.on-ne {
  border-radius: 0px 13px 13px 0px;
  margin: -29px 42px;
  background: #00A58F;
  border: 1px solid #00A58F;
  width: 40px;
  height: 27px;
}

// <
.te_pic {
  width: 8px;
  height: 15px;
  margin-left: 15px;
  margin-top: 5px;
}
// >
.te_pic {
  width: 8px;
  height: 15px;
  margin-left: 15px;
  margin-top: 5px;
}

// 两个框子
.live-wing {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
// 左框子
.wing-wo {
  border-radius: 5px;
  border: 1px solid #00A58F;
  margin: 40px -235px;
  width: 150px;
  height: 93px;
  
}
.wing-we {
  border-radius: 5px;
  border: 1px solid #00A58F;
  margin: -135px -67px;
  width: 150px;
    height: 93px;
    overflow: hidden;
}
.wo_pic {
  width: 150px;
    height: 93px;
    overflow: hidden;
}
.wing-no {
  color: #00A58F;
  font-size: 12px;
  margin-left: -340px;
  margin-top: 170px;
}

.video-left{
  width: 699px;
  height:450px;
}
</style>
