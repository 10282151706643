/**
 * 商城Vuex-actions
 */
 export default {
  // 保存用户名
  saveUserName(context,username) {
    context.commit('saveUserName',username);
  },
  // 保存购物车数量
  saveCartCount(context,count) {
    context.commit('saveCartCount',count);
  },
  //保存token值
  saveToken(context,token){
    context.commit('saveToken',token);
  },
    //保存审核状态值
  saveaudit_statet(context,audit_statet){
      context.commit('saveaudit_statet',audit_statet);
  }
}