<template>
  <div class="village" v-if="detail.articleInfos">
    <div class="header">
      <img src="https://qwyun.ai-library.com/imgs/village@2x.png" class="header_pic" />
    </div>
    <div class="main">
      <el-row>
        <el-col :span="12">
          <div
            class="grid-content1"
            @click="handleJump(detail.articleInfos[0])"
          >
            <div class="leftBottom">
              <img :src="detail.articleInfos[0].image" class="contentImg" />
            </div>
            <div class="leftBottom2">
              <img src="https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323180939616.png" class="contentImgdown" />
              <h3>{{ detail.articleInfos[0].title }}</h3>
              <p
                class="dewy-content"
                v-html="
                  detail.articleInfos[0].content.slice(0,100).replace(
                    /<\/?(img)[^>]*>/gi,
                    ''
                  )
                "
              ></p>
            </div>
          </div>
        </el-col>
        <el-col :span="12">

          <div
            class="dis-flex box-content"
            v-for="(item, index) in detail.articleInfos"
          >
            <div class="left-content">
              <div >
                <div class="f-24" style="font-weight: bold;margin: 5px 0 10px 0;">{{ item.createTime.slice(8, 10) }}</div>
                <div class="f-16">{{ item.createTime.slice(0, 7) }}</div>
              </div>
              <div class="line-content"></div>
            </div>
            <div
              class="right-content"
              style="cursor: pointer"
              @click="handleJump(item)"
            >
              <div class="rigthOne">
                <h1>{{ item.title }}</h1>
                <p
                  class="dewy-content"
                  v-html="item.content.slice(0,50).replace(/<\/?(img)[^>]*>/gi, '')"
                ></p>
                <hr style="margin-top: 20px" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="load-more" @click="loadMore">查看更多>></div>
  </div>
</template>

<script>
import { checkLogin } from "@/util/index";

export default {
  props: ["detail"],
  name: "village",
  methods: {
    loadMore() {
      this.$router.push({
        path: "/village",
      });
    },
    handleJump(row) {
      // console.log(checkLogin());
      // if (checkLogin()) {
        this.$router.push({
          path: "/villageDetail",
          query: {
            id: row.id,
          },
        });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  color: #00A58F;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  padding-bottom: 20px;
}
.village {
  // background-color: #f2f2f2;
  background: url("https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323144524591.png") no-repeat;
  background-size: 100% 100%;
}
.leftBottom {
  margin: 0 0 10px 0;
}
.rightBottom {
  margin-left: 120px;
  text-align: left;
  cursor: pointer;
}
.grid-content1 {
  text-align: left;
  cursor: pointer;
}
.grid-content2 {
  text-align: right;
  margin-bottom: 35px;
}
/*内容上部*/
.header {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0 50px 0;
}
/*内容中部*/
.main {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
}
/*中部左边图片*/
.contentImg {
  height: 300px;
  width: 600px;
}
.leftBottom2 h3 {
  margin: -77px 45px 17px 35px;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
}
.leftBottom2 p {
  margin: -20px 35px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  color: #ffffff !important;
}
.contentImgdown {
  height: 100px;
  width: 600px;
  text-align: left;
}

/* 数字 */
.content1 {
  text-align: left;
  margin-left: 30px;
}
.content1 h1 {
  margin-left: 8px;
  font-size: 24px;
}
.content1 p {
  font-size: 16px;
  line-height: 19px;
  margin-left: -8px;
}
.content1 div {
  width: 1px;
  height: 50px;
  background: #333333;
  margin: -50px 70px;
}
/*中部右部边input*/
.rigthOne{
  cursor: pointer;
}
.rigthOne h1 {
  font-size: 19px;
}
.rigthOne p {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  line-height: 19px;
  margin: 5px 0px;
}

.rigthOne hr {
  border-style: none none solid none;
  border-color: #e2e2e2;
  width: 450px;
  margin-left: -100px;
  margin-top: 37px;
}
.rigthTwo {
  border: solid 1px black;
  height: 60px;
  width: 550px;
  background-color: #f2f2f2;
}
.rigthThree {
  border: solid 1px black;
  height: 60px;
  width: 550px;
  background-color: #f2f2f2;
}
.rigthFour {
  border: solid 1px black;
  height: 60px;
  width: 550px;
  background-color: #f2f2f2;
}
/*内容底部*/
.footer {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0 30px 0;
}
/*查看按钮*/
.more {
  border: none;
  background-color: #f2f2f2;
}
/*查看更多 字体*/
.moreText {
  font-size: 18px;
  color: #00A58F;
}
/*上部标题*/
.header_pic {
  width: 284px;
  height: 82px;
}

.dewy-content {
  display: -webkit-box;
  width: 400px;
  line-height: 1.5;
  margin: 50px 0 0 50px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.f-16 {
  font-size: 16px;
}
.f-24 {
  font-size: 24px;
}
.dis-flex {
  display: flex;
}
.box-content {
  align-items: center;
}
.left-content {
  display: flex;
  padding-left: 20px;
  margin-right: 20px;
  align-items: center;
}
.line-content {
  width: 1px;
  height: 50px;
  background: #333333;
  margin: 0 20px;
}
.right-content {
  text-align: left;
}
.rigthOne h1 {
  font-size: 19px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
