import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import VueCookie from 'vue-cookie' // 引入cookie


// 1.安装插件
Vue.use(Vuex)
// 2.state对象
const state = {
  audit_state:'',
  username: '', // 登录用户名
  dialogVisible:false,//登录对话框
  cartCount: 0, // 购物车商品数量
  token:VueCookie.get('userId')? VueCookie.get('userId'):'',//token认证
}
// 3.store对象
const store = new Vuex.Store({
  state,
  mutations,
  actions
})

export default store