<template>
    <div class="bg-show">
        <div class="container">
            <div class="headerTop">
                <img src="https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323112504483.png" alt="" class="top-logo2">
            </div>
                <div class="col-6">
                    <el-row :gutter="24">
                        <el-col :span="8" v-for="(item,index) in detail.showInfos" :key="index">
                            <div class="grid-content bg-purple" @click="buyNow(item)">
                                <img class="img-alt" :src="item.image" alt="图片">
                                <div  class="title-bottom">
                                    <p class="title">
                                        {{item.name}}
                                        <span class="pull-right"> <i class="icon-ivews"></i>{{item.view}}</span>
                                    </p>
                                    <p class="active-time">
                                        <!-- <span class="pull-left">活动时间：{{item.projectTime}}</span> -->
                                      
                                    </p>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
        </div>
        <div class="load-more" @click="loadMore">查看更多>></div>

    </div>
</template>

<script>
export default {
    props:['detail'],
    data(){
        return{
            //数据列表渲染
     
        }
    },
    methods:{
        loadMore() {
      this.$router.push({
        path: "/show",
        query: {},
      });
    },

        buyNow(data) {
          console.log(data.id);
          this.$router.push({
            path:'/showDetail',
            query:{
              id:data.id
            }
          })
        },
    }
}
</script>


<style lang="scss" scoped>



.load-more {
  color: #00A58F;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  padding-bottom: 20px;
}
.bg-show{
        background: url("https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070317445039977.png") no-repeat;
        background-size: 100% 100%;
}

.headerTop{
    text-align: center;  
    padding-top:58px;
    padding-bottom: 29px;
}
.icon-ivews{
    background: url("/imgs/icon_guankan@2x.png") no-repeat;
    background-size: contain;
    width:22px;
    height:16px;
    display: inline-block;
    vertical-align: -2px;
    margin:0 10px;
    margin-top:5px;
    position: absolute;
    right:10px;
}
.top-logo{
    width:240px;
    height:60px;
}

.top-logo2{
    width: 284px;
    height: 82px;
}
.col-6{
    .grid-content{
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 0px 5px 10px 0px rgba(160,141,141,0.33);
        height:350px;
        width:380px;
        background: #fff;
        margin-bottom: 30px;
        .img-alt{
            width:380px;
            height: 220px;
        }
    }
    .title-bottom{
        padding:0 20px;
        color:#333;
        .title{
            font-size: 21px;
            position:relative;
        }
        .active-time{
            margin-top:30px;
            font-size: 18px;
           
        }
    }
}
</style>
