/**
 * 商城Vuex-mutation
 */
export default {
  saveUserName(state,username) {
    state.username  = username;
  },
  saveCartCount(state,count) {
    state.cartCount = count;
  },
  saveToken(state,token) {
    state.token = token;
  },
  setLoginDialogVisible:(state, dialogVisible) => {
    state.dialogVisible = dialogVisible
  },
  saveaudit_statet:(state, audit_state) => {
    state.audit_state = audit_state
  },
}