<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      res: {}
    };
  },
  mounted() {
    if (this.$cookie.get('userId')) {
       this.getUserInfo();
       this.getAuditState();
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$api.mall.getUserInfo({}).then((res = {}) => {
        // 保存到vuex里面
        console.log(res.data)
        this.$store.dispatch('saveUserName', res.data.niceName);
      });
    },
    getAuditState(){
      this.$api.mall.getDicts("yun_culture_brand_audit_state").then((res = {}) => {
        // 保存到vuex里面
        console.log(res.data)
        this.$store.dispatch('saveaudit_statet', res.data);
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss'; // 引入重置样式表（覆盖浏览器用户代理样式）
// @import '@/assets/scss/modal.scss'; // 引入模态框样式表

</style>

<style lang="scss" scoped>

</style>
