<template>
  <div>

  <el-row>
  <el-col :span="24">
      <!-- 顶部导航 -->
      <div class="header">
              <!-- <img src="@/assets/image/index/logo.png" alt=""> -->
          <a href="/">
        <img src="@/assets/image/pic_logo@2x.png" alt="logo" class="logo_img pull-left">
      </a>
        <div class="pull-right login_input">
          <el-input
          placeholder="请输入关犍词（直播、资讯、主题）"
          class="input_right"
          clearable
          suffix-icon="el-icon-search"
          @change="handleSearchName"
          v-model="searchName">
        </el-input>
          <!-- <input class="input_right" type="text" @input="handleInput" placeholder="请输入关犍词（直播、资讯、主题）"> -->
          <div class="login">
            <i class="icon-person"></i>
            <el-dropdown  v-if="username" @command="handleCommand">
              <span class="el-dropdown-link">
                <span style="color:#D12D28">{{username}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                | <i class="" @click="handleExit">退出</i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">我的收藏</el-dropdown-item>
                <el-dropdown-item command="2">我的内容</el-dropdown-item>
                <el-dropdown-item command="3">我的订单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <span @click="handlePerson"><span style="color:#D12D28">{{username}}</span> | <i class="" @click="handleExit">退出</i></span> -->
            <span v-else>
             <i @click="handleRegister"> 注册 </i>| <i @click="handleLogin">登录</i>
            </span>  
          </div>
        </div>
    </div>
  </el-col>
</el-row>
  <logins  @close="close" :dialogVisible="dialogVisible"/>
</div>
 
</template>

<script>
import  logins from '@/components/logins.vue'
import { mapState,mapActions } from 'vuex';
export default {
  name: 'nav-header',
  components:{
    logins
  },
  data() {
    return {
      code:'',
      phone:'',
      password:'',
      imageCode:'',
      uuid:'',
      searchName:'',
      // dialogVisible:false,
    };
  },
  mounted() {
    
  },
  computed: {
    // 获取store中state值
    // 方式一：
    // username() {
    //   return this.$store.state.username;
    // },
    // cartCount() {
    //   return this.$store.state.cartCount;
    // }
    // 方式二：
    ...mapState(['username', 'cartCount','dialogVisible'])
  },
  filters: {
    // 过滤器处理价格
    currency(val) {
      if (!val) return '0.00';
      return '￥' + val.toFixed(2) + '元';
    }
  },
  methods: {
    handleSearchName(row){
        console.log(row)
        if(row){
            this.$router.push({
            path:'/searchName',
            query:{
              name:row
            }
          })
        }
    },
    handleCommand(command) {
        if(command==1){
          this.$router.push('/mylive');
        }
        if(command==2){
          this.$router.push('/homeLive');
        }
        if(command==3){
          // 我的订单
          this.$router.push('/myOrder');

        }
      },
    ...mapActions(['saveUserName']),
    //退出按钮
    handleExit(){
      this.$confirm('是否退出当前登录账号', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
             // 清除用户ID
        this.$cookie.set('userId', '', { expires: '-1' });
        // 清除用户名
        this.$store.dispatch('saveUserName', '');
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          // location.reload();
          this.$router.push({ path: "/index" }).catch(()=>{});
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
    },
    close(){
        this.$store.commit('setLoginDialogVisible', false)
    },
    // //去注册页面
    getRegister(){
      this.$router.push('/register');
    },
    //账号对话框tabs选项效果
    handleClick(tab, event) {
        console.log(tab, event);
      },
    // 登录弹窗
    handleLogin(){
       this.$store.commit('setLoginDialogVisible', true)
    },
    //注册按钮
    handleRegister(){
        this.getRegister();
    },

    // 登录
    goLogin() {
      this.$router.push('/login');
    },

    // 获取购物车商品数量
    getCartSum() {
      this.$api.mall.getCartSum({}).then((res = 0) => {
        // 保存到vuex里面
        this.$store.dispatch('saveCartCount', res);
      });
    },
    // 跳转购物车
    goToCart() {
      this.$router.push('/cart');
    },
    handlePerson(){
      this.$router.push('/mylive');
    }
  }
};
</script>

<style lang="scss" scoped>
i{
  font-style: normal;
}
@import "./../assets/scss/icon.scss";
// @import "./../assets/scss/config.scss";
.header {
   width:1200px;
   margin:0 auto;
   overflow: hidden;
   .logo_img{
      width:352px;
      height:60px;
      margin-top:36px;
      margin-bottom: 28px;
   }
   .input_right{
      // border:1px solid #999;
      width:460px;
      height:40px;
      border-radius: 20px !important;
      // padding:14px 20px 12px 29px;
      position: relative;
      margin-right:41px;
      // background: url('/imgs/icon_sous@2x.png') no-repeat right 20px center  / 18px 18px; 
      &::placeholder{
        color:#999;
      }
   }
   .login_input{
      margin-top:48px;
   }
   .login{
      font-size:18px;
      display: inline-block;
      cursor: pointer;
   }
   .icon-person{
     display: inline-block;
     background: url('/imgs/icon_renxiang@2x.png') no-repeat;
     background-size: contain;
     vertical-align: -4px;
     width:18px;
     height:22px;
     margin-right:10px;
    
   }
  .nav-topbar {
    height: 39px;
    line-height: 39px;
    background-color: #333333;
    color: #b0b0b0;
  }
}
.phone-input{
  margin-top:20px;
}
.container-button{
  text-align: center;
}
.login_button{  
  background: #00A58F;
  border-radius: 36px;
  border:none;
  color:#fff;
  font-size:22px;
  padding: 8px 159px;
  margin:10px;
  cursor: pointer;
  
}
.login_accout{
  color:#C7171D;
  cursor: pointer;
}


//图形验证码
.login-code {
  margin-top: 20px;
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.login-code-img {
  height: 38px;
}
</style>

<style>

.customClass .el-dialog__body{
  padding:0 20px;
}
</style>
