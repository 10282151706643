import FrontBill from '@/views/Fees/Usual/Components/FrontBill';
<template>
    <div
      class="player"
      :style="'width:' + widthHeigt[0] + '%' + 'height:' + widthHeigt[1] + '%'">
      <div class="video" id="player-container-id" ref="tcplayer"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "TcPlayer",
    components: {},
    props: {
      widthHeigt: {
        type: Array,
        default: () => {
          return [100, 100];
        }
      },
      playVideo: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        player: ""
      };
    },
    created() {},
    watch: {
      playVideo: function() {
        this.play();
      }
    },
    mounted() {
      this.play();
    },
    methods: {
      play() {
        let dom = document.getElementById("player-container-id");
        while (dom.hasChildNodes()) {
          //当div下还存在子节点时 循环继续
          dom.removeChild(dom.firstChild);
          // this.player.destroy();
        }
        // console.log(JSON.stringify(this.playVideo));
        let objectString = JSON.stringify(this.playVideo);
        if (objectString != "{}") {
          this.player = new TcPlayer("player-container-id", {
            m3u8: this.playVideo.m3u8, 
            m3u8_sd: this.playVideo.m3u8_sd,
            m3u8_hd: this.playVideo.m3u8_hd,
            flv_sd: this.playVideo.flv_sd, //请替换成实际可用的播放地址
            flv_hd: this.playVideo.flv_hd, //请替换成实际可用的播放地址
            autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
            flash: false,
            h5_flv: true,
            width: this.widthHeigt[0] + "%", //视频的显示宽度，请尽量使用视频分辨率宽度
            height: this.widthHeigt[1] + "%", //视频的显示高度，请尽量使用视频分辨率高度
          });
        }
      },
      destroyPlay() {
        this.player.destroy();
      }
    },
    destroyed() {
      // 页面销毁，同时也销毁 TcPlayer
      this.player.destroy();
    }
  };
  </script>
  <style lang="scss" scoped>
  .player {
    width: 100%;
    height: 100%;
    ::v-deep .video {
      width: 100%;
      height: 100%;
      .vcp-player {
        width: 100%;
        height: 100%;
        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
  </style>
  
  