<template>
    <div>
      <nav-header />
      <HeadNav />
      <div class="wrapper">
       <div class="container">
          <elMenu />
          <router-view />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NavHeader from '@/components/NavHeader.vue';
  import HeadNav from '@/components/myNav.vue'; //
  import  elMenu from '@/pages/my/elMenu.vue'

  export default {
    name: 'nav-home',
    components: { NavHeader,HeadNav, elMenu }
  };
  </script>
  
  <style lang='scss' scoped>
  .wrapper{
  background: #F2F2F2;
  }
.container{
  padding-top:20px;
  padding-bottom:120px;
  overflow: hidden;


}
  </style>