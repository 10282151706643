<template>
  <div class="container_banner_nav">
    <div class="swiper">
      <el-carousel height="425px">
        <el-carousel-item
          v-for="(item, i) in bannerData"
          :key="i"
          @click.native="bannerJump(item)"
        >
          <img :src="item.image" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="header">
      <ul class="ul_nav">
        <li
          v-for="(item, i) in navList"
          :key="i"
          :index="item.name"
          @click="handleJump(item, i)"
        >
          <span :class="item.active"> </span>
          <img :src="item.img" :alt="item.navItem" class="img_nav" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerData: [],
      active: "",
      navList: [
        {
          name: "/index",
          navItem: "首页",
          img: "/imgs/bth_shouye1@2x.png",
          active: "",
          index: 0,
        },
        {
          name: "/live",
          navItem: "看直播",
          img: "/imgs/bth_zhibo@2x.png",
          active: "",
          index: 1,
        },
        {
          name: "/Delivery",
          navItem: "文化配送",
          img: "/imgs/bth_peisong@2x.png",
          active: "",
          index: 2,
        },
        {
          name: "/product",
          navItem: "文创产品",
          img: "/imgs/bth_wenchuang@2x.png",
          active: "",
          index: 3,
        },
        {
          name: "/village",
          navItem: "乡村振兴",
          img: "/imgs/bth_xiangcun@2x.png",
          active: "",
          index: 4,
        },
        {
          name: "/show",
          navItem: "线上展览",
          img: "/imgs/bth_zhanlan@2x.png",
          active: "",
          index: 5,
        },
        {
          name: "/show",
          navItem: "电子图片馆",
          img: "/imgs/bth_dushu@2x.png",
          active: "",
          index: 6,
        },

      ],
    };
  },
  methods: {
    Banner() {
      this.$api.mall.getBanner().then((res) => {
        this.bannerData = res.data;
      });
    },
    handleJump(item) {
      if(item.index==6){
          window.open('http://45.121.55.49:8081/5clib/home.action', '_blank');
        return false;
      }
      this.$router.push({ path: item.name });
      this.navList = [
        {
          name: "/index",
          navItem: "首页",
          img: "/imgs/bth_shouye1@2x.png",
          active: "",
          index: 0,
        },
        {
          name: "/live",
          navItem: "看直播",
          img: "/imgs/bth_zhibo@2x.png",
          active: "",
          index: 1,
        },
        {
          name: "/Delivery",
          navItem: "文化配送",
          img: "/imgs/bth_peisong@2x.png",
          active: "",
          index: 2,
        },
        {
          name: "/product",
          navItem: "文创产品",
          img: "/imgs/bth_wenchuang@2x.png",
          active: "",
          index: 3,
        },
        {
          name: "/village",
          navItem: "乡村振兴",
          img: "/imgs/bth_xiangcun@2x.png",
          active: "",
          index: 4,
        },
        {
          name: "/show",
          navItem: "线上展览",
          img: "/imgs/bth_zhanlan@2x.png",
          active: "",
          index: 5,
        },
        {
          name: "/show",
          navItem: "电子图片馆",
          img: "/imgs/bth_dushu@2x.png",
          active: "",
          index: 6,
        },
      ];
      this.acurrent(item.index);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    acurrent(active) {
      this.navList.length;
      let arrActive = [
        "bth_shouye1_active@2x",
        "bth_zhibo_active@2x",
        "bth_peisong_active@2x",
        "bth_wenchuang_active@2x",
        "bth_xiangcun_active@2x",
        "bth_zhanlan_active@2x",
        "bth_dushu@2x_acitve",
      ];

      this.navList[active].active = "active_index";
      this.navList[active].img = `/imgs/${arrActive[active]}.png`;
    },
    bannerJump(data) {
      if (data.target == "") {
        return;
      }
      var str = new RegExp("http");
      let result = str.test(data.target); //test方法返回值为(true或者false)
      if (result) {
        window.open(data.target, "_blank");
      } else {
        this.$router.push({
          path: data.target,
          query: {},
        });
      }
      // window.open(newpage.href, "_blank");
    },
  },
  created() {
    this.Banner();
    let active = this.$route.path;
    this.navList.forEach((item) => {
      if (item.name == active) {
        this.acurrent(item.index);
      }
    });
  },
};
</script>

<style>
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-menu--horizontal > .el-menu-item {
  padding: 15px !important;
  text-align: center;
}
.el-menu--horizontal > .el-menu-item {
  line-height: 30px;
}
.el-menu-item {
  font-size: 18px !important;
  /* margin:20px !important;; */
}
.el-menu-item:hover {
  background: #ddd;
}
.el-menu-item.is-active {
  color: #ea5b2c !important;
  font-size: 18px !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  /* border-bottom: inherit !important; */
  border-bottom: none !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
}
</style>
<style lang="scss" scoped>
.banner-img {
  width: 100%;
}
.header {
  width: 100%;
  height: 150px;
  background: url("/imgs/pic_ditu@2x.png") no-repeat;
  background-size: 100% 100%;
  overflow-x: auto;
  .ul_nav {
    width: 1440px;
    margin: 0 auto;
    .active_index {
      width: 84px;
      height: 142px;
      margin-top: 4px;
      background: url("/imgs/pic_xuanzhong@2x.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 68px;
    }
    .active_live {
      width: 84px;
      height: 142px;
      margin-top: 4px;
      background: url("/imgs/pic_xuanzhong@2x.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 68px;
    }
    li {
      float: left;
      position: relative;
      cursor: pointer;
    }
    span {
      display: inline-block;
    }
    .img_nav {
      width: 205px;
      height: 74px;
      margin: 34px 0 27px 0;
    }
  }
}
.el-menu-demo {
  background: #ddd;
}
</style>
