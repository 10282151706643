<template>
  <div class="container1">
    <div class="header">
       <!-- 数据顶部头像 -->
      <img src="/imgs/pic_shuju@2x.png" alt="" class="header_pic" />
    </div>
    <div class="container">
      <el-row :gutter="24">
          <el-col :span="6" v-for="(item,index) in detail.arrayList" :key="index">
            <div class="col-img">
              <p class="item-number">{{item.number}}</p>
              <p class="item-title">
                 {{item.text}}
              </p>
            </div>
           </el-col>
        </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props:['detail'],
  data(){
    return{
    }
  },
  created(){

  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.container1 {
  width:100%;
  background: url("https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323130594776.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 50px;
}
.header {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 30px 0;
  .header_pic {
    width: 300px;
  }
}
.headier {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  padding-left: 6%;
}

.col-img{
  background: url("https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323250048723.png") no-repeat;
  background-size: 100% 100%;
  width:265px; //-20
  height:237px;  //-20 
  margin-left: 20px;
  text-align: center;
  .item-number{
    margin:5px auto 20px 25px;
    padding-top: 50px;
    font-size: 54px;
    color:#01A48E
  }
  .item-title{
    color:#333333;
    font-size: 20px;
    margin-top:10px;
    margin-left:25px;
  }
}
</style>
