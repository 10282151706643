<template>
  <div class="delivery-container">
    <div class="effed">
      <img src="/imgs/delivery/pic_biaoti@2x.png" alt="" class="effed_pic" />
    </div>
    <!-- 文艺演出 -->
    <div class="delivery-inge">
      <div
        class="delivery-tinges"
        v-for="(item, index) in detail.projects"
        :key="index"
        :class="{ 'delivery-active': index == deliveryClick }"
        @click="deliveryNameClick(index)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="delivery-reify" v-if="detail.projects">
      <!-- 轮播 -->
      <div style="width: 800px; margin: 32px auto 0px">
        <el-carousel
          :interval="4000"
          type="card"
          indicator-position="none"
          arrow="always"
          :autoplay="true"
          height="410px"
          width="750px"
          class="card-carousel"
        >
          <el-carousel-item
            v-for="(item, index) in detail.projects[deliveryClick]
              .projectSubInfos"
            :key="index"
          >
            <div>
              <img :src="item.image" class="card-img" />
              <!-- 卡片名称 -->
              <h2 class="card-title">{{ item.name }}</h2>
              <div class="card-line">
                <img
                  src="/imgs/delivery/icon_shijian@2x.png"
                  class="card-icon"
                />
                <div class="card-text">时间：{{ item.projectTime }}</div>
              </div>
              <div class="card-line">
                <img src="/imgs/delivery/icon_dizhi@2x.png" class="card-icon" />
                <div class="card-text">地址：{{ item.address }}</div>
              </div>
              <div class="card-btn" @click="buyNow(item)">点单</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="load-more" @click="loadMore">查看更多>></div>
  </div>
</template>
<script>
export default {
  props: ["detail"],
  data() {
    return {
      loading: false,
      list: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      showNextPage: true, //加载更多：是否显示按钮
      busy: false, //滚动加载，是否触发
      // 当前点击类目的index
      deliveryClick: 0,
    };
  },
  mounted() {},
  methods: {
    /**
     * 查看更多
     */
    loadMore() {
      this.$router.push({
        path: "/Delivery",
        query: {},
      });
    },
    /**
     * 轮播类目切换
     * @param {*} index
     */
    deliveryNameClick(index) {
      this.deliveryClick = index;
    },
    /**
     * 点击秒杀事件
     * @param {*} data
     */
    buyNow(data) {
      this.$router.push({
        path: "/DeliveryDetail",
        query: {
          id: data.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.delivery-container {
  background: url("https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070323235167593.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom:20px;
}
// 看直播
.effed {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0 50px 0;
  .effed_pic {
    width: 244px;
    height: 82px;
  }
}

.delivery-inge {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

// 文艺演出
.delivery-effed {
  border-radius: 22px;
  border: 1px solid #00A58F;
  text-align: center;
  margin-top: -22px;
  margin-left: 33.5%;
  width: 106px;
  height: 37px;
  cursor: pointer;
}

.delivery-active {
  background: #00A58F !important;
  color: #ffffff !important;
  font-size: 16px;
}

// 展览展示 文化讲座
.delivery-tinges {
  border-radius: 22px;
  border: 1px solid #00A58F;
  text-align: center;
  margin-top: -22px;
  margin-left: 34px;
  width: 106px;
  height: 37px;
  color: #00A58F;
  font-size: 16px;
  cursor: pointer;
  line-height: 37px;
}

// 大框子
.delivery-reify {
  width: 1200px;
  margin: 0 auto;
}

// 轮播
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //   line-height: 200px;
  margin: 0;
}

// 这里设置下四周的阴影
.el-carousel__item:nth-child(2n) {
  background: #fff;
  //   opacity: 0.8;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(160, 141, 141, 0.33);
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(160, 141, 141, 0.33);
}

// 轮播内容
::v-deep.card-carousel .el-carousel__container {
  margin: 0px 15px 15px 5px;
}
// 以下为卡片内容
.card-img {
  width: 390px;
  height: 220px;
}
.card-title {
  font-size: 18px;
  color: #351c20;
  padding-left: 20px;
  margin: 10px 0px;
}
.card-line {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
}
.card-icon {
  width: 22px;
  height: 24px;
}
.card-text {
  font-size: 16px;
  color: #351c20;
  font-weight: 400;
  padding-left: 10px;
}
// 卡片按钮
.card-btn {
  background: #00A58F;
  color: #fff;
  height: 50px;
  border-radius: 20px;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  margin: 15px 20px;
}
.load-more {
  color: #00A58F;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
</style>
