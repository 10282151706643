<template>
    <!-- 文创产品 -->
    <div class="delivery-container" v-if="detail.cultureInfos">
      <div class="effed">
        <img src="/imgs/product/pic_top.png" alt="" class="effed_pic" />
      </div>
      <!-- 文艺演出 -->
      <div class="delivery-inge">
      <div
        class="delivery-tinges"
        v-for="(item, index) in detail.cultureInfos"
        :key="index"
        :class="{ 'delivery-active': index == deliveryClick }"
        @click="deliveryNameClick(index)"
      >
        {{ item.name }}
      </div>
    </div>
      <div class="container">
        <div class="col-6" style="margin-top:30px;">
                    <el-row :gutter="24">
                        <el-col :span="8" v-for="(item,index) in detail.cultureInfos[deliveryClick].cultureSubInfos" :key="index">
                            <div class="grid-content bg-purple" @click="buyNow(item)">
                                <img class="img-alt" :src="item.image" alt="图片">
                                <div  class="title-bottom">
                                    <p class="title">
                                        {{item.name}}
                                    </p>
                                    <!-- <p class="active-time">
                                        <span class="">¥{{item.price}}</span>
                                    </p> -->
                                </div>
                            </div>
                        </el-col>
                    </el-row>
           </div>
      </div>
      <div class="load-more" @click="loadMore">查看更多>></div>

    </div>

    


  </template>
  <script>
  export default {
    props:['detail'],
    data() {
      return {
            deliveryClick:0,
            //数据列表渲染
        loading: false,
        list: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        showNextPage: true, //加载更多：是否显示按钮
        busy: false, //滚动加载，是否触发
        cardList: [
          {
            id: 0,
            img: "/imgs/delivery/pic_carousel.png",
            title: "隆昌市图书馆国庆活动",
            time: "2022-09-12",
            address: "隆昌市丰泽区黄龙街道",
          },
          {
            id: 1,
            img: "/imgs/delivery/pic_carousel.png",
            title: "隆昌市图书馆国庆活动",
            time: "2022-09-12",
            address: "隆昌市丰泽区黄龙街道",
          },
          {
            id: 2,
            img: "/imgs/delivery/pic_carousel.png",
            title: "隆昌市图书馆国庆活动",
            time: "2022-09-12",
            address: "隆昌市丰泽区黄龙街道",
          },
        ],
      };
    },
    mounted() {},
    methods: {
      /**
       * 查看更多
       */
      loadMore() {
        // 三种路由跳转方式
        // this.$router.push('/order/pay')
        /*this.$router.push({
            name:'order-pay',
            query:{
              orderNo
            }
          })*/
        this.$router.push({
          path: "/product",
          query: {},
        });
      },
      /**
       * 点击秒杀事件
       * @param {*} data
       */
       buyNow(data) {
          console.log(data.id);
          this.$router.push({
            path:'/ProductDetail',
            query:{
              id:data.id
            }
          })
        },
      //轮播类目切换
      deliveryNameClick(index) {
       this.deliveryClick = index;
      },
    },
  };
  </script>
  <style lang="scss" scoped>

.load-more {
  color: #00A58F;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  padding-bottom: 20px;
}
  .delivery-container {
    background: url("/imgs/product/pic_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .delivery-inge {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

  // 看直播
  .effed {
    text-align: center;
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0 50px 0;
    .effed_pic {
    width: 244px;
    height: 82px;
    }
  }
  

// 文艺演出
.delivery-effed {
  border-radius: 22px;
  border: 1px solid #00A58F;
  text-align: center;
  margin-top: -22px;
  margin-left: 33.5%;
  width: 106px;
  height: 37px;
  cursor: pointer;
}

.delivery-active {
  background: #00A58F !important;
  color: #ffffff !important;
  font-size: 16px;
}

  // 文艺演出
  .delivery-effed {
    border-radius: 22px;
    border: 1px solid #00A58F;
    background: #00A58F;
    text-align: center;
    margin-top: -22px;
    margin-left: 33.5%;
    width: 106px;
    height: 37px;
    cursor: pointer;
  }
  
  .delivery-effed p {
    color: #ffffff;
    font-size: 16px;
    margin-top: 7%;
  }
  
  // 展览展示 文化讲座
  .delivery-tinges {
  border-radius: 22px;
  border: 1px solid #00A58F;
  text-align: center;
  margin-top: -22px;
  width: 106px;
  height: 37px;
  color: #00A58F;
  font-size: 16px;
  cursor: pointer;
  line-height: 37px;
}

 
  
.col-6{
    .grid-content{
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 0px 5px 10px 0px rgba(160,141,141,0.33);
        max-height:340px;
        padding-bottom: 10px;
        width:380px;
        background: #fff;
        margin-bottom: 30px;
        .img-alt{
            width:380px;
            height: 220px;
        }
    }
    .title-bottom{
        padding:0 20px;
        color:#333;
        .title{
            font-size: 21px;
        }
        .active-time{
            margin-top:30px;
            font-size: 21px;
            color:#C8171D;
           
        }
    }
}

//tabs选项效果
.live-li{
  display: inline-block;
    li{
    float: left;
    border-radius: 22px;
    border: 1px solid #00A58F;
    text-align: center;
    width: 106px;
    height: 37px;
    line-height: 37px;
    color:#00A58F;
    font-size: 16px;
    cursor: pointer;
    margin:20px;
    }
    .active{
      background: #00A58F;
      color:#fff;
      border-radius: 22px;
    }
}
  </style>
  