<template>
<!-- 底部导航 -->
  <div class="nav-footer">
    <div class="header"><img src="@/assets/image/pic_biaoti5@2x.png" class="header_pic"/></div>
    <div class="main">
      <el-row :gutter="10" class="row-bg">
        <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5" v-for="(item,index) in detail.partnerInfos" :key="index">
          <div class="grid-content contentLine">
            <el-link :href="item.target"  target="_blank">
              <img :src="item.image" alt="">
            </el-link>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <el-row type="flex" justify="space-between">
        <el-col :span="12">
          <div class="footerText">
            <el-row>地址:犍为县黄旗大道文体中心2号门(犍为县文化馆)</el-row>
            <el-row>联系电话：0833-4221466</el-row>
            <el-row>版权所有:犍为县文化馆 </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="footerImg">
            <template><img src="https://jianwei-1301398717.cos.ap-guangzhou.myqcloud.com/banner/1/2024/6/2023070711593639079.jpg" class="footerQR"/></template>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props:['detail'],
  name: 'nav-footer'
};
</script>

<style lang="scss" scoped>
/*背景色*/
.nav-footer{
  background-color: #E8DDD1;
}
.el-row {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}
/*内容上部*/
.header{
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding:50px 0 50px 0;
}
/*上部标题*/
.header_pic{
  width: 300px;
  height: 75px;
}
/*内容中间*/
.main{
  text-align: center;
  width: 1200px;
  margin: 0 auto;
}
/*中部 行标题格式*/
.linetitle{
  width: 100%;
  height: 100%;
}

.contentText{
  text-align: center;
  padding: 20px 0 15px 15px;
}
/*中间 图片格式*/
.logo{
  width: 90%;
  height: 90%;
}
.contentLine{
  padding: 10px;
}
/*查看更多 按钮*/
.more{
  color: #8E5A1F;
  background-color: #E4D3C0;
  border: none;
  width: 100%;
  height: 100%;
  margin-top: 15px;
}
.moreText{
  font-size: 16px;
}
.ck{
  line-height: 70px;
}
/*内容底部*/
.footer{
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding:50px 0 50px 0;
}
/*二维码*/
.footerQR{
  width: 120px;
  height: 120px;
}
.row-bg{
  background-color: #E4D3C0;
}

.footerText{
  text-align: left;
  font-size: 15px;
  color:#8E5A1F ;
}
.footerImg{
  text-align: right;
}
</style>
