/**
 * 商城模块接口列表
 */
import axios from '@/api/http.js'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const mall = {
    homeText(dictType) {
     return axios.get(`api/app/home/text/${dictType}`, {  });
  },

  //字典
   getDicts(dictType) {
    return axios.get(`api/system/dict/data/type/${dictType}`, {  });
    },
    // 获取验证码
    getCodeImg() {
        return axios.get(`api/captchaImage`, {  });
    },
    //发送短信验证码
    getCodePhone(type,phone) {
        return axios.get(`api/common/sms/send/vertifyCode/${type}/${phone}`, {  });
    },
    // 商品列表
    goodsList(params) {
        return axios.get(`api/products`, { params });
    },
    // 密码登录
    login(params) {
        return axios.post(`api/app/login`, params);
    },
    //忘记密码
    changePwd(params) {
        return axios.post(`api/app/change/pwd`, params);
    },

     // 验证码登入
    codeLogin(params){
        return axios.post(`api/app/code/login`, params);
    },
    // 退出
    logout(params) {
        return axios.post(`api/user/logout`, params);
    },
    // 注册
    register(params) {
        return axios.post(`api/app/register`, params);
    },
    //轮播图
    getBanner() {
        return axios.get('api/app/home/banner');
    },
    // 获取用户信息
    getUserInfo() {
        return axios.get('api/app/user/profile');
    },
   // 官网主体数据
    appHome() {
      return axios.get(`api/app/home`, {  });
    },
    //文化配送类别
    homeProjectType() {
        return axios.get(`api/app/home/project/type`, {  });
      },
    //直播列表
    videoLisst(params){
      return axios.get(`api/app/home/video/list?state=${params.state}&time=${params.time}&search=${params.search}`);
    },
    //直播详情
    videoDetailL(params){
      return axios.get(`api/app/home/video/${params.id}`);
   },
   //回放直播
     videoRecordDetailL(params){
    return axios.get(`api/app/home/video/record/${params.id}`);
    },
    //文化配送列表
    projectList(params){
        return axios.get(`api/app/home/project/list?typeId=${params.typeId}&time=${params.time}&search=${params.search}`);
     },
      //文化配送详情
     projectListDetailL(params){
        return axios.get(`api/app/home/project/${params.id}`);
     },
       //文化配送类别
    homeCultureType() {
        return axios.get(`api/app/home/culture/type`, {  });
      },
      //详情
      homeCultureDetailL(params) {
        return axios.get(`api/app/home/culture/${params.id}`, {  });
      },
    //文创产品列表
    cultureList(params){
        return axios.get(`api/app/home/culture/list?typeId=${params.typeId}&time=${params.time}&search=${params.search}&brandId=${params.brandId}`);
    },
 
      //文化配送类别
    homeShowType() {
        return axios.get(`api/app/home/show/type`, {  });
      },
       //文章列表
     articleList(params){
        return axios.get(`api/app/home/article/list/?typeId=${params.typeId}`);
    },
    //文章详情
    articleListdetaial(params){
      return axios.get(`api/app/home/article/${params.id}`);
   },
   ShowInfoDetail(params){
    return axios.get(`api/app/home/show/info/${params.id}`);
 },
    showList(query){
        return axios.get(`api/app/home/show/list`,{params:query});

    },
    culutrebrandList(query){
      return axios.get(`api/app/home/culture/brand/list`,{params:query});
   },
   //直播列表
    myvideoList(query){
        return axios.get(`api/app/my/home/video/list`,{params:query});
    },
    myHomearticleist(query){
      return axios.get(`api/app/my/home/article/list`,{params:query});
  },
  myHomecultureList(query){
    return axios.get(`api/app/my/home/culture/list`,{params:query});
},
       //我的直播回放列表
   myvideorecondList(query){
        return axios.get(`api/app/my/home/video/recond/list`,{params:query});
    },
    //查询用户下拉列表
    searchUser(query){
      return axios.get(`api/yun/user/select`,{params:query});
  },
    //我的展览
    myShowList(query){
      return axios.get(`api/app/my/home/show/list`,{params:query});
      },
  //直播提交
    postmyvideoList(query){
      return axios.post(`api/app/my/home/video`,query);
  },
   //申请企业名录
   postmycultureList(query){
    return axios.post(`api/app/my/home/culture/brand`,query);
   },
   postUserWord(query){
    return axios.post(`api/app/user/word`,query);
   },
   //申请文化配送
   postHomeproject(query){
    return axios.post(`api/app/my/home/project`,query);
   },
  postmyshowList(query){
    return axios.post(`api/app/my/home/show`,query);
},
    //申请文创产品
    postMyCulture(query){
      return axios.post(`api/app/my/home/culture`,query);
    },
    //直播回放列表
    myvideorecondList(query){
      return axios.get(`api/app/my/collect/video/recond/list`,{params:query});
    },
    //我的文化配送
   myCollectProjectList(query){
    return axios.get(`api/app/my/collect/project/list`,{params:query});
  },

  // 我的文章内容
   postHomeArticle(query){
      return axios.post(`api/app/my/home/article`,query);
    },
    //我的文创产品
    myCollectCulureList(query){
      return axios.get(`api/app/my/collect/culture/list`,{params:query});
    },
    //我的文章列表
    myCollectarticleList(query){
      return axios.get(`api/app/my/collect/article/list`,{params:query});
    },
    //我的企业名录
    myCultureBrandList(query){
      return axios.get(`api/app/my/home/culture/brand/list`,{params:query});
    },
    listyunType() {
      return axios.get('/api/yun/yunType/list');
   },
   listcultureType() {
    return axios.get('/api/yun/cultureType/list');
 },
    //文化类别
    getprojectType() {
      return axios.get('/api/yun/projectType/list');
    },
    //展厅类别列表
    getshowType() {
      return axios.get('/api/yun/showType/list');
    },
    orderIdType(params){
      return axios.get(`/api/app/user/order/${params.id}/${params.type}/${params.num}`);
    },
    orderIdAgainType(params){
      return axios.get(`/api/app/user/order/again/${params.id}/${params.type}/${params.num}`);
    },
    orderProjectList(query){
      return axios.get(`api/app/my/home/project/order/list`,{params:query});
    },
    orderCultureList(query){
      return axios.get(`api/app/my/home/culture/order/list`,{params:query});
    },
    //我的收藏-文化配送
    homeProjectList(query){
      return axios.get(`api/app/my/home/project/list`,{params:query});
    },
    //我的收藏-文创产品
    myCollectCulture(query){
          return axios.get(`api/app/my/collect/culture/list`,{params:query});
     },  
        //我的收藏-展览
    mycolectShowList(query){
      return axios.get(`api/app/my/collect/show/list`,{params:query});
    }, 
    //我的收藏-栏目文章
    mycolectarticleList(query){
        return axios.get(`api/app/my/collect/article/list`,{params:query});
    }, 
    collectId(params){
      return axios.get(`/api/app/user/collect/${params.id}/${params.type}/${params.operate}`);
    },
    supportId(params){
      return axios.get(`/api/app/user/support/${params.id}/${params.type}/${params.operate}`);
    },
    companyDetailId(params){
      return axios.get(`api/app/home/culture/brand/${params.id}`);
   },
   searchList(query){
    return axios.get(`api/app/home/search/list`,{params:query});
  },
  //品牌下拉
  cultureBrandSelect(query){
    return axios.get(`api/app/my/home/culture/brand/select`,{params:query});
  },
  yumColumnSelect(query){
    return axios.get(`api/yun/column/select`,{params:query});
  },

    // 获取收货地址
    getAddress() {
        return axios.get('api/shippings');
    },
    articleType() {
      return axios.get('api/app/home/article/type');
  },
    // 收货地址增删改
    handleAddress(method, url, params) {
        return axios[method](url, params);
    },
    // 根据字典类型查询字典数据信息
   getDicts(dictType) {
    return axios.get(`api/system/dict/data/type/` + dictType);
  },
  videoLiveId(id) {
    return axios.get(`api/yun/video/live/` + id);
  }
}

export default mall;